import {
    createRouter,
    createWebHistory
} from 'vue-router'

const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: () => import('@/components/HelloWorld.vue')
    // }
    {
        path: '/Entrance',
        name: 'Crew_Entrance',
        component: () => import('../components/views/Entrance.vue'),
    },
    {
        path: '/',
        name: 'Crew_Main',
        component: () => import('../components/views/MainView.vue'),
    },
    {
        path: '/SkeletalTest',
        name: 'SkeletalTest',
        component: () => import('../components/views/skeleton/SKFeedCardItem.vue'),
    },
    {
        path: '/RecordMealPage',
        name: 'RecordMealPage',
        component: () => import('../components/views/record/RecordMealPage.vue'),
    },
    {
        path: '/RecordExercisePage',
        name: 'RecordExercisePage',
        component: () => import('../components/views/record/RecordExercisePage.vue'),
    },
    {
        path: '/RecordBoardPage',
        name: 'RecordBoardPage',
        component: () => import('../components/views/record/RecordBoardPage.vue'),
    },
    {
        path: '/ProfilePage/:crewKey?/:encryptId?',
        name: 'ProfilePage',
        component: () => import('../components/views/profile/ProfilePage.vue'),
    },
    {
        path: '/ProfilePageV2/:crewKey?/:encryptId?',
        name: 'ProfilePageV2',
        component: () => import('../components/views/profile/ProfilePageV2.vue'),
    },
    {
        path: '/ProfileManagerPage',
        name: 'ProfileManagerPage',
        component: () => import('../components/views/profile/ProfileManagerPage.vue'),
    },
    {
        path: '/ProfileEditPage',
        name: 'ProfileEditPage',
        component: () => import('../components/views/profile/ProfileEditPage.vue'),
    },
    {
        path: '/LoginPage',
        name: 'LoginPage',
        component: () => import('../components/views/login/LoginPage.vue'),
    },
    {
        path: '/ProfileSettingPage',
        name: 'ProfileSettingPage',
        component: () => import('../components/views/profile/ProfileSettingPage.vue'),
    },
    /** 미참여자 페이지 */
    {
        path: '/FeedNonParticipantsPage',
        name: 'FeedNonParticipantsPage',
        component: () => import('../components/views/feed/FeedNonParticipantsPage.vue'),
    },
    /** 크루 준비하기 페이지 */
    {
        path: '/FeedCrewPreparationPage',
        name: 'FeedCrewPreparationPage',
        component: () => import('../components/views/feed/FeedCrewPreparationPage.vue'),
    },
    {
        path: '/SetupUseInfoPage',
        name: 'SetupUseInfoPage',
        component: () => import('../components/views/setup/SetupUseInfoPage.vue'),
    },
    {
        path: '/SetupUseInfoPageV2',
        name: 'SetupUseInfoPageV2',
        component: () => import('../components/views/setup/SetupUseInfoPageV2.vue'),
    },
    {
        path: '/SetupPage',
        name: 'SetupPage',
        component: () => import('../components/views/setup/SetupPage.vue'),
    },
    {
        path: '/FeedCrewBeforeStartPage',
        name: 'FeedCrewBeforeStartPage',
        component: () => import('../components/views/feed/FeedCrewBeforeStartPage.vue'),
    },
    {
        path: '/ReportStatisticPage',
        name: 'ReportStatisticPage',
        component: () => import('../components/views/report/ReportStatisticPage.vue'),
    },
    {
        path: '/NotificationPage',
        name: 'NotificationPage',
        component: () => import('../components/views/notification/NotificationPage.vue'),
    },
    {
        path: '/ManagerCrewList/:pageType?',
        name: 'ManagerCrewList',
        component: () => import('../components/views/manager/ManagerCrewList.vue'),
    },
    {
        path: '/ManagerAdminHub',
        name: 'ManagerAdminHub',
        component: () => import('../components/views/manager/ManagerAdminHub.vue'),
    },
    {
        path: '/CrewBotPage',
        name: 'CrewBotPage',
        component: () => import('../components/views/manager/CrewBotPage.vue'),
    },
    {
        path: '/ManagerMorningMessagePage',
        name: 'ManagerMorningMessagePage',
        component: () => import('../components/views/manager/ManagerMorningMessagePage.vue'),
    },
    {
        path: '/ManagerNightMessagePage',
        name: 'ManagerNightMessagePage',
        component: () => import('../components/views/manager/ManagerNightMessagePage.vue'),
    },
    // 임시
    {
        path: '/IntroductionGroupDetailModal',
        name: 'IntroductionGroupDetailModal',
        component: () => import('../components/views/introduction/IntroductionGroupDetailModal.vue'),
    },
    {
        path: '/FeedNoCaptinBeforeStartPage',
        name: 'FeedNoCaptinBeforeStartPage',
        component: () => import('../components/views/feed/FeedNoCaptinBeforeStartPage.vue'),
    },
    {
        path: '/LevelHistoryPage',
        name: 'LevelHistoryPage',
        component: () => import('../components/views/level/LevelHistoryPage.vue'),
    },
    {
        path: '/LevelScorePage',
        name: 'LevelScorePage',
        component: () => import('../components/views/level/LevelScorePage.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router