import { isEqualOrBeforeYmdt, parseYmd } from '@learnbody/date'
import { isAppleDevice } from '@/utils/utils'

export const RANDOM_BOX_SPECIFIC_DATE = '2023-09-25 00:00:00'
export const VERSION_7_SPECIFIC_DATE = '2023-11-12 00:00:00'

/**
 * 속해있는 크루가 특정 날짜에 도달했거나 이전인지 여부
 * @param { string } crewKey crewKey (ex. 20231001_55)
 * @param { string } specificDate 체크할 특정일 'yyyy-MM-dd HH:mm:ss'형식의 string
 * @returns true|false
 */
export function checkSpecificDateCrew(crewStartDate) {
    const crewDate = parseYmd(crewStartDate, isAppleDevice)
    return isEqualOrBeforeYmdt(crewDate, RANDOM_BOX_SPECIFIC_DATE, isAppleDevice)
}
export function checkVersion7DateCrew(crewStartDate) {
    // 관리자로 접근하는 경우 예외처리
    let location = window.location.pathname
    if (location.indexOf('CrewBotPage') > -1) return false

    const crewDate = parseYmd(crewStartDate, isAppleDevice)
    return isEqualOrBeforeYmdt(crewDate, VERSION_7_SPECIFIC_DATE, isAppleDevice)
}
