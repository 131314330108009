import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import messages from './assets/language'
// import VueGtag from 'vue-gtag'
import { createPinia } from 'pinia'
import { VueQueryPlugin } from 'vue-query'
import config from '@/config'
import VueScrollPicker from 'vue-scroll-picker'
import { Skeletor } from 'vue-skeletor'
import Vue3Lottie from 'vue3-lottie'
import VueVirtualScroller from 'vue-virtual-scroller'
import LBDesignSystem from '@learnbody/common-design-system'

import '@learnbody/common-design-system/dist/assets/css/bundle.css'

import 'vue-scroll-picker/lib/style.css'
// import 'vue-skeletor/dist/vue-skeletor.css';

const i18n = createI18n({
    legacy: false,
    locale: 'ko',
    fallbackLocale: 'ko',
    messages: messages,
})

if (config.buildMode === 'dev') {
    console.log('buildMode DEV')
    createApp(App)
        .use(router)
        .use(createPinia())
        .use(VueQueryPlugin)
        .use(i18n)
        .component(Skeletor.name, Skeletor)
        .use(VueScrollPicker)
        .use(Vue3Lottie)
        .use(VueVirtualScroller)
        .use(LBDesignSystem)
        .mount('#app')
} else {
    console.log('buildMode Prod')
    createApp(App)
        .use(router)
        .use(createPinia())
        .use(VueQueryPlugin)
        .use(i18n)
        .component(Skeletor.name, Skeletor)
        .use(VueScrollPicker)
        .use(Vue3Lottie)
        .use(VueVirtualScroller)
        .use(LBDesignSystem)
        // .use(
        //     VueGtag,
        //     {
        //         config: {
        //             id: 'G-P2JPHJB4FZ', // Google Analytics의 Tracking ID를 넣어준다
        //         },
        //     },
        //     router,
        // )
        .mount('#app')
}
