import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'

export const useinfoBtnDataStore = defineStore('infoBtnData', () => {
    const { t } = useI18n()
    const prepatationBtnList = reactive([
        {
            title: t('feed_preparation_page_6'),
            state: 'beforecomplete',
            code: 'MEAL',
        },
        {
            title: t('exercise_mission_guide_1'),
            state: 'beforecomplete',
            code: 'EXERCISE',
        },
        {
            title: t('feed_preparation_page_7'),
            state: 'beforecomplete',
            code: 'DEPOSIT',
        },
        {
            title: t('feed_preparation_page_8'),
            state: 'beforecomplete',
            code: 'ACTIVITY_REWARD',
        },
        {
            title: t('feed_preparation_page_9'),
            state: 'beforecomplete',
            code: 'KAKAOTALK',
        },
        {
            title: t('feed_preparation_page_10'),
            state: 'beforecomplete',
            code: 'MEETING',
        },
        {
            title: t('feed_preparation_page_11'),
            state: 'complete',
            promiseTime: '0/0 00:00 약속 완료',
            code: 'PROMISE',
        },
        {
            title: t('feed_preparation_page_13'),
            state: '',
            code: 'Q&A',
        },
        {
            title: t('feed_preparation_page_14'),
            state: '',
            code: 'INQUIRY',
        },
    ])
    const prepatationBtnListV2 = reactive([
        {
            title: t('feed_preparation_page_6'),
            state: 'beforecomplete',
            code: 'MEAL',
        },
        {
            title: t('exercise_mission_guide_1'),
            state: 'beforecomplete',
            code: 'EXERCISE',
        },
        {
            title: t('feed_preparation_page_7'),
            state: 'beforecomplete',
            code: 'DEPOSIT',
        },
        {
            title: t('feed_preparation_page_15'),
            state: 'beforecomplete',
            code: 'ACTIVITY_REWARD',
        },
        {
            title: t('feed_preparation_page_16'),
            state: 'beforecomplete',
            code: 'STICKER',
        },
        {
            title: t('feed_preparation_page_17'),
            state: 'beforecomplete',
            code: 'MY_TREE',
        },
        {
            title: t('feed_preparation_page_18'),
            state: 'beforecomplete',
            code: 'CLEAN_OPERATION_POLICY',
        },
        {
            title: t('feed_preparation_page_11'),
            state: 'complete',
            promiseTime: '0/0 00:00 약속 완료',
            code: 'PROMISE',
        },
    ])

    const changePrepatationBtnList = () => {
        let targetIndex = prepatationBtnList.findIndex((item) => item.code === 'ACTIVITY_REWARD')
        let changeData = {
            title: t('feed_preparation_page_15'),
            state: 'beforecomplete',
            code: 'ACTIVITY_REWARD',
        }
        prepatationBtnList.splice(targetIndex, 1, changeData)
    }

    const changePreparationListActivityReward = () => {
        let targetIndex = prepatationBtnList.findIndex((item) => item.code === 'ACTIVITY_REWARD')
        let changeData = {
            title: t('feed_preparation_page_8'),
            state: 'beforecomplete',
            code: 'ACTIVITY_REWARD',
        }
        prepatationBtnList.splice(targetIndex, 1, changeData)
    }

    return { prepatationBtnList, changePrepatationBtnList, changePreparationListActivityReward, prepatationBtnListV2 }
})
