import axios from 'axios'
import config from '@/config'
import cookies from 'js-cookie'
import { useQuery } from 'vue-query'

const API_TIMEOUT = 30000 // 30s

function request(endpoint, method, data) {
    let aipUrl = config.diaryApiBaseUrl + endpoint

    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

function requestReport(endpoint, method, data) {
    let aipUrl = config.diaryReportApiBaseUrl + endpoint

    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

function requestShopBy(endpoint, method, data) {
    let aipUrl = config.shopByBaseUrl + endpoint

    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        headers: {
            accessToken: cookies.get('ncpAccessToken') || '',
            clientId: config.shopByClientId,
            Version: '1.0',
            platform: config.platform(),
            guestToken: cookies.get('guestToken') || '',
        },
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

function requestMarket(endpoint, method, data) {
    let aipUrl = config.marketApiBaseUrl + endpoint

    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

function requetCommunity(endpoint, method, data) {
    let aipUrl = config.communityDomain + endpoint

    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

function requestGuide(endpoint, method, data) {
    let aipUrl = config.guideApiBaseUrl + endpoint

    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

function requestBand(url, method, data) {
    let aipUrl = url

    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

function requestTest() {
    const response = fetch('https://api.example.com/posts')
    return response.json()
}

function usePostsQuery(queryKey, fetchData) {
    return useQuery(queryKey, fetchData)
}

function requestCrew(endpoint, method, data) {
    let aipUrl = config.crewApiBaseUrl + endpoint
    return axios({
        url: aipUrl,
        timeout: API_TIMEOUT,
        method: method,
        params: method === 'get' ? data : null,
        data: method === 'post' || method === 'put' || method === 'delete' ? data : null,
    }).catch(function (error) {
        return error.response
    })
}

export {
    request,
    requestReport,
    requestShopBy,
    requestMarket,
    requetCommunity,
    requestGuide,
    requestBand,
    requestTest,
    usePostsQuery,
    requestCrew,
}
