import cookies from 'js-cookie'
import config from '@/config'
import { useDataCenter } from '@/managers/dataCenter'
import { useAppLandingManager } from '@/managers/appLandingManager'

const dataCenter = useDataCenter()
const appLandingManager = useAppLandingManager()
/**
 * 애플 기기 여부 체크
 * @returns true|false
 */
export function isAppleDevice() {
    let userAgent = navigator.userAgent.toLocaleLowerCase()
    if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
        return true
    } else {
        return false
    }
}

/**
 * 웹 공용 뒤로가기 액션
 */
export function historyPage(isFromMobile, isForceClose) {
    if (window.location.pathname === '/' || isForceClose) {
        commonAppCall('EXIT')
    } else if (window.location.search.indexOf('direct=1') > -1) {
        commonAppCall('EXIT')
    } else if (
        isFromMobile &&
        (window.location.pathname === '/Entrance' ||
            window.location.pathname === '/RecordMealPage' ||
            window.location.pathname === '/RecordExercisePage')
    ) {
        return
    } else {
        history.back()
    }
}

/**
 * 현재 브라우져가 아닌 신규 브라우져 띄움
 * @param { String } outLinkUrl url 링크
 */
export function appOutLink(outLinkUrl) {
    // if (cookies.get('fromMobileApp') === 'true') {
    let data = {
        ViewCode: 3,
        OutLinkURL: outLinkUrl,
    }
    commonAppCall(data, true)
    // } else {
    //   window.open(outLinkUrl, '_blank')
    // }
}

/**
 * 랜딩 정보 생성 및 전송
 * @param { Object } rendingInfo 랜딩 데이터
 */
export function appLanding(rendingInfo) {
    let data = {
        ViewCode: rendingInfo.viewCode,
        RendingCode: rendingInfo.rendingCode,
        RendingData: rendingInfo.rendingData,
    }

    commonAppCall(data, true)
}

/**
 * 앱으로 랜딩 정보 전송
 * @param { Object } data 랜딩 데이터
 * @param { boolean } isAllowStringify Stringify 필요 여부
 * @returns
 */
export function commonAppCall(data, isAllowStringify) {
    if (config.isLocalDevelopment) return

    let userAgent = navigator.userAgent.toLocaleLowerCase()
    let jsonStringfyData = null

    if (isAllowStringify) {
        jsonStringfyData = JSON.stringify(data)
    } else {
        jsonStringfyData = data
    }

    if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
        return window.webkit.messageHandlers.handleAppDiary.postMessage(jsonStringfyData)
    } else {
        return window.handleAppDiary.callAndroid(jsonStringfyData)
    }
}

/**
 * 유효기간 1년으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
export function setCookieForYear(key, value) {
    // App 정보는 만료시간 360일 후 설정
    const expAppInfo = new Date()
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
    cookies.set(key, value, { expires: expAppInfo })
}

/**
 * 유효기간 x일으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
export function setCookieForDays(key, value, day) {
    // App 정보는 만료시간 day일 후 설정
    const expAppInfo = new Date()
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * day)
    cookies.set(key, value, { expires: expAppInfo })
}
/**
 * 유효기간 1일으로 쿠키값 저장
 * @param { String } key
 * @param { * } value
 */
export function setCookieForDay(key, value) {
    // App 정보는 만료시간 1일 후 설정
    const expAppInfo = new Date()
    expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24)
    cookies.set(key, value, { expires: expAppInfo })
}

export function dataURLtoFile(dataurl, fileName) {
    var arr = ''
    var mime = ''
    var bstr = ''
    var n = ''
    var u8arr = ''

    try {
        arr = dataurl.split(',')
        mime = arr[0].match(/:(.*?);/)[1]
        bstr = atob(arr[1])
        n = bstr.length
        u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        if (u8arr.length === 0 || u8arr.length !== bstr.length) {
            return null
        }
    } catch (e) {
        return null
    }
    return new File([u8arr], fileName, { type: mime })
}

/**
 * iOS 키보드 자동 완성 기능 숨기기
 */
export function disableAutoCompleteForIOS() {
    if (cookies.get('AppType') === 'IOS') {
        var textFields = document.getElementsByTagName('textarea')
        if (textFields) {
            var i
            for (i = 0; i < textFields.length; i++) {
                var txtField = textFields[i]
                if (txtField) {
                    txtField.setAttribute('autocomplete', 'off')
                    txtField.setAttribute('autocorrect', 'off')
                    txtField.setAttribute('autocapitalize', 'off')
                    txtField.setAttribute('spellcheck', 'false')
                }
            }
        }
    }
}

export function checkUsableFunctionWithOSVersion(iosRefAppVersion, aosRefAppVersion) {
    let isAble = false
    try {
        const fullappversion = cookies.get('AppVersion')
        const apptype = cookies.get('AppType').toLowerCase()
        let appversion = fullappversion
        if (apptype !== 'ios') {
            if (fullappversion.indexOf('(') > -1) {
                appversion = fullappversion.split('(')[0]
            } else if (fullappversion.indexOf('_') > -1) {
                appversion = fullappversion.split('_')[0]
            }
        }

        let refAppVersion = apptype !== 'ios' ? aosRefAppVersion : iosRefAppVersion
        let arrAppVersion = appversion.split('.')
        for (let i = 0; i < arrAppVersion.length; i++) {
            let value = arrAppVersion[i]
            if (Number(value) > refAppVersion[i]) {
                isAble = true
                break
            } else if (Number(value) === refAppVersion[i]) {
                if (i === arrAppVersion.length - 1) isAble = true
                else continue
            } else {
                isAble = false
                break
            }
        }
    } catch (e) {
        console.log('e : ' + e)
    }

    return isAble
}

export function compareNumNKor(a, b) {
    // Check if both elements are numbers
    if (typeof a.sortCharacter === 'number' && typeof b.sortCharacter === 'number') {
        return a.sortCharacter - b.sortCharacter
    }

    // Check if both elements are strings
    if (typeof a.sortCharacter === 'string' && typeof b.sortCharacter === 'string') {
        // Compare the strings using localeCompare for Korean characters
        return a.sortCharacter.localeCompare(b.sortCharacter, 'ko')
    }

    // Sort numbers before strings
    if (typeof a.sortCharacter === 'number') {
        return -1
    }

    // Sort strings after numbers
    return 1
}

export function sortArrayAscendWithNumNKor(array) {
    return array.sort(compareNumNKor)
}

export function compareNumNKor2(a, b) {
    // Check if both elements are numbers
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b
    }

    // Check if both elements are strings
    if (typeof a === 'string' && typeof b === 'string') {
        // Compare the strings using localeCompare for Korean characters
        return a.localeCompare(b, 'ko')
    }

    // Sort numbers before strings
    if (typeof a === 'number') {
        return -1
    }

    // Sort strings after numbers
    return 1
}

export function sortArrayAscendWithNumNKor2(array) {
    return array.sort(compareNumNKor2)
}

// 복사하기 기능
export function copyToClipboard(text) {
    if (dataCenter.getCookie(dataCenter.KEYS_SYSTEM.APP_TYPE) === 'IOS') {
        window.navigator.clipboard.writeText(text)
    } else {
        appLandingManager.appLanding(appLandingManager.VALUES_LANDING_CODE.ACTION_SAVE_CLIPBOARD, text)
    }
}
