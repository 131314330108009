// appLandingManager.js
import config from '@/config'

// 싱글톤 인스턴스를 저장할 변수
let instance = null

/**
 * Landing 매니저 키
 */
// 시스템 데이터 키값
const KEYS = {
    VIEW_CODE: 'ViewCode', // 랜딩 뷰 코드
    LANDING_CODE: 'LandingCode', // 랜딩 코드
    LANDING_DATA: 'LandingData', // 랜딩 데이터
}

/**
 * Landing 매니저 값
 */

// VIEW_CODE 키값의 값
const VALUES_VIEW_CODE = {
    INNER_APP_ACTION: 1, // 앱 내 액션
    INNER_APP_WEBVIEW: 2, // 앱 내 웹뷰 띄우기
    OUTLINK: 3, // 외부 링크
}

// LANDING_CODE 키값의 값
const VALUES_LANDING_CODE = {
    REQUEST_PUSH_PERMISSION: 'REQUEST_PUSH_PERMISSION', // 푸시 권한 요청
    REQUEST_CAMERA_PERMISSION: 'REQUEST_CAMERA_PERMISSION', // 카메라 권한 요청
    REQUEST_UPDATE_USERINFO: 'REQUEST_UPDATE_USERINFO', // 사용자 정보 업데이트 요청
    SHOW_CAMERA_ACTIONSHEET: 'SHOW_CAMERA_ACTIONSHEET', // 카메라 액션시트 보여주기
    GO_SETTING: 'GO_SETTING', // 모바일 앱 설정으로 이동
    ACTION_LOGOUT: 'ACTION_LOGOUT', // 로그아웃,
    ACTION_SAVE_CLIPBOARD: 'ACTION_SAVE_CLIPBOARD', // 클립보드에 복사
    ACTION_SHOW_COMMENT_KEYBOARD: 'ACTION_SHOW_COMMENT_KEYBOARD', // 댓글작성 키보드 표시 iOS용
    ACTION_REQUEST_APP_STORE_REVIEW: 'ACTION_REQUEST_APP_STORE_REVIEW', // 앱스토어 리뷰 요청
    ACTION_MAKE_HAPTIC: 'ACTION_MAKE_HAPTIC' // 햅틱 주기
}

/**
 * 함수
 */

/**
 * 현재 브라우져가 아닌 신규 브라우져 띄움
 * @param { String } outLinkUrl url 링크
 */
function appOutLink(outLinkUrl) {
    let data = {
        ViewCode: VALUES_VIEW_CODE.OUTLINK,
        LandingCode: '',
        LandingData: outLinkUrl,
    }
    commonAppCall(data, true)
}

/**
 * 앱 내 액션 랜딩 정보 생성 및 전송
 * @param { Object } rendingInfo 랜딩 데이터
 */
function appLanding(landingCode, landingData) {
    let data = {
        ViewCode: VALUES_VIEW_CODE.INNER_APP_ACTION,
        LandingCode: landingCode,
        LandingData: landingData,
    }

    commonAppCall(data, true)
}

/**
 * 앱 내 웹뷰띄우리 랜딩 정보 생성 및 전송
 * @param { Object } rendingInfo 랜딩 데이터
 */
function appLandingInnerWebView(landingData) {
    let data = {
        ViewCode: VALUES_VIEW_CODE.INNER_APP_WEBVIEW,
        LandingCode: '',
        LandingData: landingData,
    }

    commonAppCall(data, true)
}

/**
 * 앱으로 랜딩 정보 전송
 * @param { Object } data 랜딩 데이터
 * @param { boolean } isAllowStringify Stringify 필요 여부
 * @returns
 */
function commonAppCall(data, isAllowStringify) {
    if (config.isLocalDevelopment) return

    let userAgent = navigator.userAgent.toLocaleLowerCase()
    let jsonStringfyData = null

    if (isAllowStringify) {
        jsonStringfyData = JSON.stringify(data)
    } else {
        jsonStringfyData = data
    }

    if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
        return window.webkit.messageHandlers.handleAppDiary.postMessage(jsonStringfyData)
    } else {
        return window.handleAppDiary.callAndroid(jsonStringfyData)
    }
}

export function useAppLandingManager() {
    if (!instance) {
        instance = {
            KEYS,
            VALUES_VIEW_CODE,
            VALUES_LANDING_CODE,
            appOutLink,
            appLanding,
            appLandingInnerWebView,
        }
    }

    return instance
}
