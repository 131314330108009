import { defineStore } from 'pinia'
import { requestCrew } from '@/api'
import { useDataCenter } from '@/managers/dataCenter'

const dataCenter = useDataCenter()
const defaultParams = {
    appType: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.APP_TYPE),
    appVersion: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.APP_VERSION),
    language: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE),
    country: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.COUNTRY_CODE),
    utcInterval: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.UTC_INTERVAL),
}

export const useLbUserInfoStore = defineStore('userInfo', {
    state: () => {
        return {
            userInfo: null,
            endPoint: {
                USER_INFO: 'User/Info',
                CREW_GROUP_USER_STAT_INFO: 'V1/CrewGroupUserStatInfo',
            },
            blobInfo: null,
            userAccessState: '',
            crewList: [],
            isReload: false,
            crewGroupUserStatInfo: null,
        }
    },
    actions: {
        async postUserInfo() {
            const resp = await requestCrew(
                `${this.endPoint.USER_INFO}/${dataCenter.getCookie(dataCenter.KEYS_USERINFO.ENCRYPT_ID)}`,
                'post',
                defaultParams,
            )
            if (resp.data !== undefined && resp.data !== null) {
                this.blobInfo = resp.data.blobInfo
                this.crewList = resp.data.userInfoList
            }
            return resp.data
        },
        async getCrewGroupUserStatInfo() {
            const resp = await requestCrew(
                `${this.endPoint.CREW_GROUP_USER_STAT_INFO}/${dataCenter.getCookie(
                    dataCenter.KEYS_USERINFO.ENCRYPT_ID,
                )}/${dataCenter.getCookie(dataCenter.KEYS_USERINFO.CREW_KEY)}`,
                'post',
                defaultParams,
            )

            if (resp.data !== undefined && resp.data !== null) {
                this.crewGroupUserStatInfo = resp.data
            }

            return resp.data
        },
        getRecentUserInfo(userInfoList) {
            // 크루를 변경해서 메인을 리로드한 경우 getUserInfo 호출로 현재 진행중이거나 완료된 크루중 0번째 크루가 선택되는 현상으로 인해 플래그 추가
            if (dataCenter.getCookie(dataCenter.KEYS_ETC.IS_RELOAD) === 'Y') {
                // IS_RELOAD가 'Y'인 경우 선택된 크루의 정보를 바인딩한다.
                dataCenter.setCookieForDay(dataCenter.KEYS_ETC.IS_RELOAD, 'N')
                let tempUserInfo = userInfoList.find(
                    (item) => item.crewKey === dataCenter.getCookie(dataCenter.KEYS_USERINFO.CREW_KEY),
                )
                if (tempUserInfo) {
                    this.userInfo = tempUserInfo
                }
            } else {
                // 참여 상태 우선순위
                let arrCrewStatusCodePriority = ['ING', 'READY', 'END', 'ONLY_APP_USER']
                this.userInfo = null

                // 우선순위에 해당하는 사용자 정보 반환
                for (let i = 0; i < arrCrewStatusCodePriority.length; i++) {
                    let tempUserInfo = userInfoList.find(
                        (item) => item.crewStatusCode === arrCrewStatusCodePriority[i] && !item.isFired,
                    )
                    if (tempUserInfo) {
                        this.userInfo = tempUserInfo
                        break
                    }
                }
            }
            return this.userInfo
        },
        // 자기소개 작성 후 데이터 관련 변경
        updateIsExistIntroduction() {
            this.userInfo.isExistIntroduction = true
        },
        // 프락치크루원인지 체크
        checkIsGPTCrewMember() {
            const isGPTCrewMember = this.userInfo.encryptId.indexOf('AgentF') > -1
            return isGPTCrewMember
        },
    },
})
