<template>
    <div>
        <router-view />
    </div>
</template>

<script setup>
import config from '@/config'
import { useDataCenter } from '@/managers/dataCenter'
import { useLbUserInfoStore } from '@/stores/lbUserInfo'
import { useLbBeforeStartV1Store } from '@/stores/lbBeforeStartV1'
import { useinfoBtnDataStore } from '@/stores/infoBtnData'
import {
    SetHandlerPushPermission,
    UpdatePushPermission,
    UpdateCameraPermission,
    AppCallBackAction,
    SetHandlerViewNotificationPage,
    ViewNotificationPage,
    SetHandlerViewSpecificPageWithPath,
    ViewSpecificPageWithPath,
    SetHandlerViewStickerGroupModal,
    ViewStickerGroupModal,
} from '@/managers/publicMethod'
import { onBeforeMount, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { checkSpecificDateCrew } from '@/utils/crewUtils'

const dataCenter = useDataCenter()
const lbUserInfo = useLbUserInfoStore()
const lbStartInfo = useLbBeforeStartV1Store()
const useinfoBtn = useinfoBtnDataStore()
const { t } = useI18n()
const router = useRouter()

watch(
    () => lbUserInfo.userInfo,
    (newVal) => {
        if (newVal) {
            // 크루 준비하기 페이지 버튼 텍스트 변경
            // 크루 날짜에 도달했거나 이전인지 여부
            if (!checkSpecificDateCrew(lbUserInfo.userInfo?.crewStartDateString)) {
                // 랜덤박스 표시
                useinfoBtn.changePrepatationBtnList()
            } else {
                // 활동 리워드 표시
                useinfoBtn.changePreparationListActivityReward()
            }
        }
    },
    { deep: true, immediate: true },
)

const getUserInfo = () => {
    lbUserInfo.postUserInfo().then((res) => {
        lbUserInfo.getRecentUserInfo(res.userInfoList)
    })
}

const handlerViewNotificationPage = () => {
    const currentUrl = window.location.pathname
    if (currentUrl === '/') {
        document.querySelector('.main-bottom-tab-item.icon-alarm').click()
    } else {
        router.push({
            name: 'Crew_Main',
            params: {
                clickAlarm: true,
            },
        })
    }
}

const handlerViewStickerGroupModal = () => {
    const currentUrl = window.location.pathname
    if (currentUrl === '/') {
        document.querySelector('.main-top-header-tab-wrap .crew-icon').click()
    } else {
        router.push({
            name: 'Crew_Main',
            params: {
                clickAlarm: true,
            },
        })
    }
}

const handlerViewSpecificPageWithPath = (path) => {
    router.push(path)
}

// 0.7용 크루 정보 조회
const getCrewInfo = () => {
    lbStartInfo.postCrewInfoV1()
}

onBeforeMount(() => {
    if (config.isLocalDevelopment) {
        // 임시 사용자 정보데이터
        // setCookieForYear('UID', '2701858_NoEqupiMB') // 1981_NoEqupiMB(01000001023) / 2002_NoEqupiMB(01000001029)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, 'IOS')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, '2.4.06')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_OS_VERSION, '1.0')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, 'ko')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, '82')
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, -540)

        // dataCenter.setCookieForYear(dataCenter.KEYS_USERINFO.CREW_KEY, '20230821_53')
        // dataCenter.setCookieForYear(dataCenter.KEYS_USERINFO.ENCRYPT_ID, 'be78a621323c48eb')
        dataCenter.setCookieForYear('blobInfoURI', 'https://learnbodykr.blob.core.windows.net')
        dataCenter.setCookieForYear(
            'blobInfoSasKey',
            '?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2028-02-22T15:00:00Z&st=2023-02-22T15:00:00Z&spr=https,http&sig=%2Ba6yUP1%2BiDo7pzRb1Uw7vS1Eu6u6D%2BGyDpBS%2FMyIG%2Bw%3D',
        )
    }

    const urlParams = new URLSearchParams(window.location.search)
    // let uid = urlParams.get('uId')
    // if (uid !== undefined && uid !== null) setCookieForYear('UID', uid)
    let appType = urlParams.get('appType')
    if (appType !== undefined && appType !== null) dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, appType)
    let appVersion = urlParams.get('appVersion')
    if (appVersion !== undefined && appVersion !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, appVersion)
    let osVersion = urlParams.get('osVersion')
    if (osVersion !== undefined && osVersion !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.APP_OS_VERSION, osVersion)
    let language = urlParams.get('language')
    if (language !== undefined && language !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, language)
    let country = urlParams.get('country')
    if (country !== undefined && country !== null)
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, country)

    let languageCode = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE)
    t.locale = languageCode === undefined ? 'ko' : languageCode

    let utcInterval = dataCenter.getCookie(dataCenter.KEYS_SYSTEM.UTC_INTERVAL)
    if (utcInterval === undefined || utcInterval === null) {
        dataCenter.setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, -540)
    }

    // 웹뷰 생성시 사용자 정보 조회
    let encryptId = dataCenter.getCookie(dataCenter.KEYS_USERINFO.ENCRYPT_ID)
    if (encryptId !== undefined && encryptId !== null) {
        // 사용자 EncryptID 정보 재저장(쿠키만료기간갱신)
        dataCenter.setCookieForYear(dataCenter.KEYS_USERINFO.ENCRYPT_ID, encryptId)
        getUserInfo()
        // 0.7용 크루 정보 조회
        getCrewInfo()
    }
})
onMounted(() => {
    SetHandlerPushPermission(null)
    window.UpdatePushPermission = UpdatePushPermission
    window.UpdateCameraPermission = UpdateCameraPermission
    window.AppCallBackAction = AppCallBackAction
    SetHandlerViewNotificationPage(handlerViewNotificationPage)
    window.ViewNotificationPage = ViewNotificationPage
    SetHandlerViewSpecificPageWithPath(handlerViewSpecificPageWithPath)
    window.ViewSpecificPageWithPath = ViewSpecificPageWithPath
    SetHandlerViewStickerGroupModal(handlerViewStickerGroupModal)
    window.ViewStickerGroupModal = ViewStickerGroupModal
})
</script>

<style>
@import './assets/css/normalization.css';
@import '@/assets/css/common.css';
@import '@/assets/css/animation.css';
@import '@/assets/css/iconimage.css';

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
