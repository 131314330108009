import { defineStore } from 'pinia'
import { requestCrew } from '@/api'
import { useDataCenter } from '@/managers/dataCenter'

const dataCenter = useDataCenter()
const defaultParams = {
    appType: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.APP_TYPE),
    appVersion: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.APP_VERSION),
    language: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE),
    country: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.COUNTRY_CODE),
    utcInterval: dataCenter.getCookie(dataCenter.KEYS_SYSTEM.UTC_INTERVAL),
}

export const useLbBeforeStartV1Store = defineStore('beforeStartv1', {
    state: () => {
        return {
            crewInfo: null,
            unGreetingEncryptCodeList: [],
            greetingList: null,
            endPoint: {
                START_DATE_INFO: 'V1/BeforeStartDateInfoV1',
            },
            isGroupRedDot: null,
        }
    },
    actions: {
        async postCrewInfoV1() {
            const resp = await requestCrew(
                `${this.endPoint.START_DATE_INFO}/${dataCenter.getCookie(
                    dataCenter.KEYS_USERINFO.ENCRYPT_ID,
                )}/${dataCenter.getCookie(dataCenter.KEYS_USERINFO.CREW_KEY)}`,
                'post',
                defaultParams,
            )
            if (resp.data !== undefined && resp.data !== null) {
                this.crewInfo = resp.data.crewInfo
                this.unGreetingEncryptCodeList = resp.data.unGreetingEncryptCodeList
                this.greetingList = resp.data.greetingList
                this.isGroupRedDot = resp.data.isRedDotUserGroup
            }
            return resp.data
        },
        // 인사말 완료 처리
        updateUnGreetingList(encryptId, greetingCode) {
            // 인사말을 남기지 않은 크루원 목록에서 제거
            let unGreetingIdx = this.unGreetingEncryptCodeList.findIndex((id) => id === encryptId)
            if (unGreetingIdx !== -1) {
                this.unGreetingEncryptCodeList.splice(unGreetingIdx, 1)
            }

            // 인사말 데이터 업데이트
            let greetingData = this.greetingList.filter((data) => data.encryptId === encryptId)[0]
            greetingData.greetingCode = greetingCode
        },
    },
})
